<template lang='pug'>
	.cookie.cookies-info-shown(:class='{"active" : isShown}')
		h3.b1.mb-0.cookie__title {{ $t('landing.cookieTitle') }}
		p.b1.mb-0.text-grey.cookie__text {{ $t('landing.cookieText') }}
		a.cookie__link(href='/privacy' target='_blank') {{ $t('landing.cookieDesc') }}
		span.cookie__button(@click='close') {{ $t('social.understand') }}
</template>

<script>

export default {
	name: 'ModalCookie',
	data:() => ({
		isShown: false
	}),
	created () {
		this.isShown = !this.$cookies.get("cookies-info-shown");
	},
	methods : {
		close () {
			this.isShown = false;
			this.$cookies.set("cookies-info-shown", "1", '1Y');
		},
	}
}
</script>

<style lang='scss' scoped>
@import '~@/assets/css/sass/_mixins.scss';

.cookie {
	z-index: 10;
	position:fixed;
	display:none;
	flex-direction:column;
	bottom: 24px;
	left: 24px;
	padding: 20px 30px;
	width: 100%;
	max-width: 382px;
	background-color: var(--brand-bg-primary);
	border-radius: var(--border-radius-rounded);
	box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.15);

	&::before {
		content: '';
		position: absolute;
		top: 15px;
		right: 12px;
		width: 114px;
		height: 114px;
		background-image: url('/assets/img/current/cookie-writing.png');
		background-size: cover;
		transform: rotate(-10.192deg);

		@include respond-below(sm) {
			right: 1px;
		}
	}

	@include respond-below(sm) {
		bottom:6px;
		left:6px;
		right: 6px;
		width: auto;
	}

	&.active {
		display:flex
	}

	&__title {
		max-width: 244px;
		font-family: "Golos", "Nunito", sans-serif;
		font-size: 16px !important;
		line-height: calc(24/16) !important;
		font-weight: 400;
		color: var(--main-text-color) !important;
	}

	&__text {
		max-width: 244px;
		font-family: "Golos", "Nunito", sans-serif;
		font-size: 16px !important;
		font-style: normal;
		font-weight: 400;
		line-height: calc(24/16) !important;
	}

	&__link {
		width: max-content;
		font-family: "Golos", "Nunito", sans-serif;
		font-size: 16px !important;
		font-style: normal;
		font-weight: 400;
		line-height: calc(24/16) !important;
		text-decoration: none;
		color: var(--primary-text-color);
		margin: 13px 0;
	}

	&__button {
		z-index: 1;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 38px;
		font-family: "Golos", "Nunito", sans-serif;
		font-size: 14px;
		line-height: 22px;
		font-style: normal;
		font-weight: 400;
		color: var(--button-text-color);
		border-radius: var(--border-radius-rounded);
		background-color: var(--primary-text-color);
		box-shadow: var(--btn-small-box-shadow);
		transition: var(--animation-time-short);
		cursor: pointer;

		&:hover {
			background-color: #0B7BE8;
			box-shadow: 0px 2px 10px 0px rgba(#000, 0.25);
		}

		&:active {
			background: linear-gradient(260.48deg, #0071F4 5.07%, #66B4FF 98.17%);
			box-shadow: 0px 2px 10px 0px rgba(#000, 0.25);
		}
	}
}
</style>
